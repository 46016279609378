import { SpinnerCircularFixed } from "spinners-react";
import styled from "styled-components";
const LoadingSt = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  background: rgb(0, 0, 0);
  color: white;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: grid;
  place-content: center;
`;
export default function Loading() {
  return (
    <LoadingSt>
      <SpinnerCircularFixed
        className="spinner"
        size={"2rem"}
        thickness={159}
        speed={200}
        color="#ebebeb"
        secondaryColor="rgba(149, 149, 149, 0.44)"
      />
    </LoadingSt>
  );
}

import React, { useEffect, useState, createContext, Suspense } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import styled from "styled-components";
import { useAppSelector } from "./app/hooks";
import { selectLogin } from "./features/login/loginSlice";
import useLogin from "./hooks/useLogin";

// import Browser from "./components_browser/Browser";
import Home from "./components/Home";
import { Toaster } from "react-hot-toast";
import io from "socket.io-client";
import Error from "components/atoms/Error";
import DemoCode from "DemoCode";
import Spinner from "./components_browser/atoms/Spinner";
import Loading from "components/atoms/Suspense";
const Browser = React.lazy(() => import("./components_browser/Browser"));
// !socket
const socket = io(`${process.env.REACT_APP_BACKEND_URL}`);
// !Context
export type GlobalContextType = [
  context: {
    menu: boolean;
    cast: { isConnected: boolean; library: boolean };
  },
  setContext: (value: any) => void,
  socket: any
];

export const GlobalContext = createContext({});
// !Styles
const AppSt = styled.div`
  width: 100vw;
  height: 100vh;
`;
function App() {
  const login = useAppSelector(selectLogin);

  // !GLOBAL CONTEXT STATE
  const [context, setContext] = useState({
    menu: false,
    cast: { isConnected: false, library: false },
  });
  //   console.log(context.player);

  // !Local storage
  const token = localStorage.getItem("token_v2") === null ? "" : `${localStorage.getItem("token_v2")}`;

  // !Redirect
  const REDIRECT = login.token !== "" ? <Navigate to="/browser" replace /> : <Navigate to="/home" replace />;

  // !Login
  const { LOGIN } = useLogin();
  useEffect(() => {
    if (token !== null && token !== "") LOGIN(token);
  }, []);

  return (
    //     <CastProvider>
    <GlobalContext.Provider value={[context, setContext, socket]}>
      <AppSt>
        <Toaster />
        <Router>
          <Routes>
            <Route path="/" element={REDIRECT} />
            <Route path="/home/*" element={<Home />} />
            <Route path="/demo" element={<DemoCode />} />
            {login.token !== "" && (
              //     <Route path="/browser/*" element={<Browser />} />
              <Route
                path="/browser/*"
                element={
                  <Suspense fallback={<Loading />}>
                    <Browser />
                  </Suspense>
                }
              />
            )}
            <Route path="*" element={<Error />} />
          </Routes>
        </Router>
      </AppSt>
    </GlobalContext.Provider>
    //     </CastProvider>
  );
}

export default App;

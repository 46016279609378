import axios from "axios";
import useLogin from "hooks/useLogin";
import { useState } from "react";
import { toast } from "react-hot-toast";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import FormSt from "styles/FormStyled";
import { SpinnerCircularFixed } from "spinners-react";
import banner from "img/banner.webp";
const SigninSt = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  .banner_home {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .gradient_home {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgb(0, 0, 0);
    background: linear-gradient(135deg, #000000 0%, rgba(0, 0, 0, 0.8) 47%, #000000 100%);
  }
  @media only screen and (max-width: 568px) {
    align-items: flex-start;
    .banner_home,
    .gradient_home {
      display: none;
    }
  }
`;

const NewFormSt = styled(FormSt)`
  margin-top: 0;
  position: absolute;
  width: 25rem;
  @media only screen and (max-width: 568px) {
    position: relative;
  }
`;
export default function Signin() {
  const navigate = useNavigate();
  // !States
  const [state, setState] = useState({
    user_name: "",
    password: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  // !HOOKS
  const { LOGIN } = useLogin();

  // !Functions
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.currentTarget.name;
    const value = e.currentTarget.value;
    setState({ ...state, [name]: value.trim() });
  };
  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    // !Create new client
    await axios
      .post(`${process.env.REACT_APP_BACKEND_URL}/api/v1/user_signin/`, state)
      .then(function (res) {
        if (res.status === 200) {
          LOGIN(res.data.token);
          navigate("/browser");
        }
      })
      .catch(function (error) {
        console.log(error);
        toast.error(error.response.data.message);
        setLoading(false);
      });
  };
  return (
    <SigninSt>
      <img className="banner_home" src={banner} alt="" />
      <div className="gradient_home"></div>
      <NewFormSt onSubmit={onSubmit}>
        <h1 className="form_title">Iniciar sesión</h1>
        {/* <p className="form_sub_title">Es rápido y fácil.</p> */}
        <div className="form_line"></div>
        <div className="form_container_label_input">
          <label className="label" htmlFor="user">
            Nombre de usuario
          </label>
          <input
            className="input"
            type="text"
            id="user"
            name="user_name"
            value={state.user_name}
            onChange={handleChange}
            placeholder=""
            required
          />
        </div>
        <div className="form_container_label_input">
          <label className="label" htmlFor="user">
            Contraseña
          </label>
          <input
            className="input"
            type={showPassword ? "text" : "password"}
            name="password"
            id="password"
            value={state.password}
            onChange={handleChange}
            placeholder=""
            required
          />
        </div>
        <div className="form_container_checkbox_label">
          <input
            className="form_container_checkbox_label__checkbox"
            id="showPassword"
            type="checkbox"
            onClick={() => {
              setShowPassword(!showPassword);
            }}
          />
          <label className="form_container_checkbox_label__label" htmlFor="showPassword">
            Mostrar contraseña
          </label>
        </div>
        <div className="form_line"></div>
        <button className="form_button" type="submit" disabled={loading && true}>
          Ingresar
          {loading && (
            <SpinnerCircularFixed
              className="spinner"
              size={"1.5rem"}
              thickness={159}
              speed={100}
              color="#ebebeb"
              secondaryColor="rgba(255, 255, 255, 0.44)"
            />
          )}
        </button>
        <Link className="form_button button_green" type="button" to="/home/signup">
          Crear cuenta nueva
        </Link>
      </NewFormSt>
    </SigninSt>
  );
}

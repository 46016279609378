import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
const ErrorSt = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: hidden;
  background: rgb(0, 0, 0);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* padding: 4rem 2rem 2rem 2rem; */

  .error_title {
    font-family: "Inter_700";
    font-size: 4rem;
    color: white;
  }
  .error_subtitle {
    font-family: "Inter_300";
    font-size: 1.5rem;
    color: white;
  }
  .error_button {
    font-family: "Inter_700";
    font-size: 1.2rem;
    color: #000000;
    background: white;
    border-style: none;
    border-radius: 0.3rem;
    padding: 0.5rem 0.8rem;
    margin-top: 1rem;
    :hover {
      background: #dedede;
    }
  }
`;
export default function Error() {
  const navigate = useNavigate();
  return (
    <ErrorSt>
      <p className="error_title">Error</p>
      <p className="error_subtitle">Cierra todo e intenta de nuevo</p>
      <button className="error_button" onClick={() => navigate("/")}>
        Volver atrás
      </button>
    </ErrorSt>
  );
}

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";

export interface LoginIT {
  id: number;
  user_name: string;
  role: string;
  token: string;
}

const initialState: LoginIT = {
  id: 0,
  user_name: "",
  role: "",
  token: "",
};

export const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    setLogin: (state, action: PayloadAction<LoginIT>) => {
      const { id, user_name, role, token } = action.payload;
      state.id = id;
      state.user_name = user_name;
      state.role = role;
      state.token = token;
    },
  },
});

export const { setLogin } = loginSlice.actions;

// export const selectCount = (state: RootState) => state.login.value;
export const selectLogin = (state: RootState) => state.login;

export default loginSlice.reducer;

import axios from "axios";
import Spinner from "components/atoms/Spinner";
import useLogin from "hooks/useLogin";
import { useState } from "react";
import { toast } from "react-hot-toast";
import { Link, useNavigate } from "react-router-dom";
import { SpinnerCircularFixed } from "spinners-react";
import styled from "styled-components";
import FormSt from "styles/FormStyled";
import Countries from "json/Countries.json";
const SignupSt = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  place-items: start center;
  overflow-y: scroll;
  /* padding-bottom: 2rem; */
`;

export default function Signup() {
  const navigate = useNavigate();
  // !States
  const [state, setState] = useState({
    country: "",
    user_name: "",
    email: "",
    password: "",
    repeat_password: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);

//   console.log(state);

  // !HOOKS
  const { LOGIN } = useLogin();

  // !Functions
  const handleChange = (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>) => {
    const name = e.currentTarget.name;
    const value = e.currentTarget.value;
    setState({ ...state, [name]: value.trim() });
  };
  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (state.password !== state.repeat_password) return toast.error("Las contraseñas deben ser iguales");
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(state.email) === false)
      return toast.error("Introduce un email válido");
    setLoading(true);
    // !Create new client
    await axios
      .post(`${process.env.REACT_APP_BACKEND_URL}/api/v1/user_signup/`, state)
      .then(function (res) {
        if (res.status === 200) {
          toast.success("Usuario creado");
          LOGIN(res.data.token);
          navigate("/browser");
        }
      })
      .catch(function (error) {
        console.log(error);
        toast.error(error.response.data.message);
        setLoading(false);
      });
  };

  return (
    <SignupSt>
      <FormSt onSubmit={onSubmit}>
        <h1 className="form_title">Registrarte</h1>
        <p className="form_sub_title">Es rápido y fácil.</p>
        <div className="form_line"></div>
        <div className="form_container_label_input">
          <label className="label" htmlFor="user_name">
            Nombre de usuario (Nick name)
          </label>
          <input
            className="input"
            type="text"
            name="user_name"
            id="user_name"
            value={state.user_name}
            onChange={handleChange}
            placeholder=""
            required
          />
        </div>
        <div className="form_alert">
          Tu nombre de usuario servirá para que inicies sesión, no debe contener espacios.
        </div>
        <div className="form_container_label_input">
          <label className="label" htmlFor="email">
            Correo electronico
          </label>
          <input
            className="input"
            type="text"
            name="email"
            id="email"
            value={state.email}
            onChange={handleChange}
            placeholder=""
            required
          />
        </div>
        {/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(state.email) === false && (
          <div className="form_alert">Introduce un correo válido</div>
        )}
        <div className="form_container_label_input">
          <label className="label" htmlFor="country">
            País
          </label>
          <select
            className="input select"
            name="country"
            id="country"
            value={state.country}
            onChange={handleChange}
            required
          >
            {Countries.map((i: any) => (
              <option value={i.value} key={i.value}>
                {i.name}
              </option>
            ))}
          </select>
        </div>

        <div className="form_container_label_input">
          <label className="label" htmlFor="password">
            Contraseña
          </label>
          <input
            className="input"
            type={showPassword ? "text" : "password"}
            name="password"
            id="password"
            value={state.password}
            onChange={handleChange}
            placeholder=""
            required
          />
        </div>
        {state.password.length > 0 && (
          <div className="form_container_label_input">
            <label className="label" htmlFor="repeat_password">
              Repetir contraseña
            </label>
            <input
              className="input"
              type={showPassword ? "text" : "password"}
              name="repeat_password"
              id="repeat_password"
              value={state.repeat_password}
              onChange={handleChange}
              placeholder=""
              required
            />
          </div>
        )}
        <div className="form_container_checkbox_label">
          <input
            className="form_container_checkbox_label__checkbox"
            id="showPassword"
            type="checkbox"
            onClick={() => {
              setShowPassword(!showPassword);
            }}
          />
          <label className="form_container_checkbox_label__label" htmlFor="showPassword">
            Mostrar contraseña
          </label>
        </div>
        {state.password !== state.repeat_password && (
          <div className="form_alert">Las contraseñas deben ser iguales</div>
        )}

        <div className="form_line"></div>
        {/* <p className="form_tos_text">
          Al hacer clic en "Registrarte", aceptas nuestras{" "}
          <Link className="form_tos_text__link" to="/home/tos">
            Condiciones
          </Link>
          , la{" "}
          <Link className="form_tos_text__link" to="/home/privacy">
            Política de privacidad
          </Link>{" "}
          y la{" "}
          <Link className="form_tos_text__link" to="/home/privacy">
            Política de cookies.
          </Link>
        </p> */}
        <button className="form_button button_green" type="submit" disabled={loading && true}>
          Registrarte
          {loading && (
            <SpinnerCircularFixed
              className="spinner"
              size={"1.5rem"}
              thickness={159}
              speed={100}
              color="#6a6a6a"
              secondaryColor="rgba(255, 255, 255, 0.44)"
            />
          )}
        </button>
      </FormSt>
    </SignupSt>
  );
}

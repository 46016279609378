import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { Provider } from "react-redux";
import { store } from "./app/store";
import reportWebVitals from "./reportWebVitals";
import CastProvider from "react-chromecast";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  //   <React.StrictMode>

  <Provider store={store}>    <App />
    {/* </CastProvider> */}
  </Provider>

  //   </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

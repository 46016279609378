import React, { useState } from "react";
import { SpinnerCircularFixed } from "spinners-react";
import styled from "styled-components";
import FormSt from "styles/FormStyled";

// import useWindowSize from "react-use/lib/useWindowSize";
// import Confetti from "react-confetti";
import axios from "axios";
import { toast } from "react-hot-toast";
import { log } from "console";
import { useNavigate } from "react-router-dom";
// !Styles
const DemoCodeSt = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  @media only screen and (max-width: 568px) {
    align-items: flex-start;
  }
`;
const NewFormSt = styled(FormSt)`
  margin-top: 0;
  position: absolute;
  width: 25rem;
  @media only screen and (max-width: 568px) {
    position: relative;
  }
`;
const ModalSt = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: #000000;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;

  .container_username_password {
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid #242424;
    border-radius: 1rem;
    padding: 1rem;
    background: #151515;
    .username {
      font-size: 2rem;
      margin-bottom: 1rem;
    }
    .password {
      font-size: 2rem;
      margin-bottom: 1rem;
    }
    .alert {
      font-size: 0.9rem;
      color: #ff0055;
    }
  }
  @media only screen and (max-width: 568px) {
    align-items: flex-start;
  }
`;
function DemoCode() {
  const navigate = useNavigate();
  // !States
  const [state, setState] = useState({ code: "" });
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [user, setUser] = useState({
    username: "",
    password: "",
  });

  // !Handle change
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setState({ ...state, code: e.currentTarget.value });
  };
  // !Submit
  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    setLoading(true);
    e.preventDefault();
    await axios
      .post(`${process.env.REACT_APP_BACKEND_URL}/api/v1/redeem_code`, state)
      .then(function (res) {
        setUser(res.data);
        setLoading(false);
        setShowModal(true);
        toast.success(res.data.message);
      })
      .catch(function (error) {
        setLoading(false);
        toast.error(error.response.data.message);
      });
  };
  //   const { width, height } = useWindowSize();
  return (
    <DemoCodeSt>
      <NewFormSt onSubmit={onSubmit}>
        <h1 className="form_title">Canjea tu código</h1>
        {/* <p className="form_sub_title">Es rápido y fácil.</p> */}
        <div className="form_line"></div>
        <div className="form_container_label_input">
          <label className="label">Código</label>
          <input
            className="input"
            type="text"
            name="code"
            value={state.code}
            onChange={handleChange}
            placeholder=""
            required
          />
        </div>

        <div className="form_line"></div>
        <button className="form_button" type="submit" disabled={loading && true}>
          Canjear
          {loading && (
            <SpinnerCircularFixed
              className="spinner"
              size={"1.5rem"}
              thickness={159}
              speed={100}
              color="#ebebeb"
              secondaryColor="rgba(255, 255, 255, 0.44)"
            />
          )}
        </button>
      </NewFormSt>

      {showModal && (
        <ModalSt className="modal">
          {/* <Confetti width={width} height={height} /> */}
          <NewFormSt
            onSubmit={() => {
              navigate("/");
            }}
          >
            <h1 className="form_title">Usuario</h1>
            <p className="form_sub_title">Guarda muy bien estos datos.</p>
            <div className="form_line"></div>
            <div className="form_container_label_input">
              <label className="label">Nombre de usuario</label>
              <input
                className="input"
                type="text"
                name="username"
                value={user.username}
                onChange={handleChange}
                placeholder=""
                required
              />
            </div>

            <div className="form_container_label_input">
              <label className="label">Contraseña</label>
              <input
                className="input"
                type="text"
                name="password"
                value={user.password}
                onChange={handleChange}
                placeholder=""
                required
              />
            </div>
            <div className="form_line"></div>
            <button className="form_button" type="submit" disabled={loading && true}>
              Ir a inicio
            </button>
          </NewFormSt>
        </ModalSt>
      )}
    </DemoCodeSt>
  );
}

export default DemoCode;

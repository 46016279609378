import { setLogin } from "../features/login/loginSlice";
import { useDispatch } from "react-redux";
import jwt_decode from "jwt-decode";
export default function useLogin() {
  const dispatch = useDispatch();
  interface token {
    id: number;
    user_name: string;
    role: string;
    token: string;
  }

  // !SET LOCAL STORAGE
  const populateStorage = (token: string) => {
    localStorage.setItem("token_v2", token);
  };

  // !SET LOGIN
  const LOGIN = (token: string) => {
    const decode: token = jwt_decode(token);
    dispatch(setLogin({ id: decode.id, user_name: decode.user_name, role: decode.role, token: token }));
    populateStorage(token);
  };

  // !SET LOGOUT
  const LOGOUT = () => {
    dispatch(setLogin({ id: 0, user_name: "", role: "", token: "" }));
    populateStorage("");
  };

  return { LOGIN, LOGOUT };
}
